<template>
  <div class="container page-wrapper itemWrapp">
    <a class="linkItem" :href="item.link">
      <div class="page-inner">
        <div class="row">
          <div
            class="el-wrapper"
            :class="{ border: $store.getters.GET_CLIENT_LOGINED }"
          >
            <div class="box-up">
              <img  class="img" :src="imageLink(item.image)" />
              <div class="img-info">
                <div class="info-inner">
                  <span class="p-company">GoMobile`s</span>
                  <span class="p-name">{{ item.name }}</span>
                </div>
                <div class="a-size">
                  {{ item.description || "Описание отсутствует" }}
                </div>
              </div>
            </div>
            <!-- 
            <div class="box-down">
              <div class="h-bg">
                <div class="h-bg-inner"></div>
              </div>

              <a class="cart" :href="item.link">
                <span class="price">Go</span>
                <span class="add-to-cart">
                  <span class="txt">{{ item.name }}</span>
                </span>
              </a>
            </div> -->
            <a :href="item.link" class="itemServiceLink">Go</a>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data: () => ({
    show: false,
  }),
  methods: {
    imageLink(link) {
      var images = require.context("../assets/img/", false, /\.svg$/);
      return images("./" + link + ".svg");
      // return `@/assets/img/${link}.svg`
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/serviceItem.scss";
</style>
