<template>
  <div class="welcomeMain">
    <img class="welcomeImage" src="../assets/img/people.svg" />
    <div class="welcomeForm">
      <div class="welcomePage">
        <div class="helper">
          <Helper />
        </div>
        <div class="welcome-title">Добро пожаловать</div>
        <div class="welcome-title">{{ itemData.welcometext }}</div>
        <p class="description">
          {{ itemData.description }}
        </p>
        <div v-if="!itemData.isAdmin">
          <LoginUser />
          <span class="loginlinks problemEntry" @click="dialog = !dialog">
            Проблемы со входом?
          </span>
        </div>
        <div v-else>
          <GoogleSinginBtn />
          <a class="loginlinks toadminLink mt-3" href="/">На главную</a>
        </div>
      </div>
      <v-dialog v-model="dialog" max-width="434px">
        <div class="dialogWrapper">
          <div class="dialogClose" @click="dialog = false">
            <span class="material-icons"> close </span>
          </div>
          <div class="modal-container">
            <div class="dialogTitle problemEntry">Проблемы со входом?</div>
            <p>
              Если вы не помните логин или пароль, обратитесь к своему
              аккаунт-менеджеру или напишите нам на почту:
            </p>
            <div class="dialogTitle">
              <a
                href="mailto:clients@gomobile.ru?subject=Creative%20approval%20system%20-%20issue"
                >clients@gomobile.ru</a
              >
            </div>
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import GoogleSinginBtn from "./GoogleSinginBtn";
import LoginUser from "@/components/LoginUser";
import Helper from "./Helper";
export default {
  components: {
    GoogleSinginBtn,
    LoginUser,
    Helper,
  },
  props: ["itemData"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<style  lang="scss" scoped>
@import "../assets/css/welcomeuser.scss";
</style>